import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, IconButton, Modal, Tabs, Tab } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import config from '../config';
import { Box } from '@mui/material';
import { radioGroupBoxstyle } from './ui/RadioGroupBoxStyle'

const Teams = () => {
    const [teams, setTeams] = useState([]);
    const [newTeamName, setNewTeamName] = useState('');
    const [error, setError] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [players, setPlayers] = useState([]);
    const [newPlayerName, setNewPlayerName] = useState('');
    const [activeTab, setActiveTab] = useState(1);

    useEffect(() => {
        fetchTeams();
    }, []);

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${config.API_BASE_URL}/api/getTeams.php`);
            setTeams(response.data.teams ?? []);
        } catch (error) {
            console.error('Error fetching teams:', error);
            setError('Error fetching teams');
        }
    };

    const handleAddTeam = async () => {
        try {
            const response = await axios.post(`${config.API_BASE_URL}/api/addTeam.php`, { teamName: newTeamName });
            if (response.data.success) {
                setTeams([...teams, newTeamName]);
                setNewTeamName('');
                fetchTeams();
                console.log(response.data);
            } else {
                setError('Error adding team');
            }
        } catch (error) {
            console.error('Error adding team:', error);
            setError('Error adding team');
        }
    };

    const handleDeleteTeam = async (index) => {
        try {
            const response = await axios.post(`${config.API_BASE_URL}/api/deleteTeam.php`, { teamName: teams[index] });
            if (response.data.success) {
                setTeams(teams.filter((_, i) => i !== index));
                fetchTeams();
                console.log(response.data);
            } else {
                setError('Error deleting team');
            }
        } catch (error) {
            console.error('Error deleting team:', error);
            setError('Error deleting team');
        }
    };

    const handleEditTeam = (index, updatedTeamName) => {
        const updatedTeams = [...teams];
        updatedTeams[index] = updatedTeamName;
        setTeams(updatedTeams);
    };

    const handleSaveTeam = async (index, updatedTeamName) => {
        try {
            const response = await axios.post(`${config.API_BASE_URL}/api/updateTeam.php`, { teamIndex: index, newTeamName: updatedTeamName });
            if (response.data.success) {
                console.log(response.data);
                fetchTeams();
            } else {
                setError('Error saving team');
            }
        } catch (error) {
            console.error('Error saving team:', error);
            setError('Error saving team');
        }
    };

    const handleOpenModal = async (team) => {
        setSelectedTeam(team);
        setOpenModal(true);
        try {
            const response = await axios.get(`${config.API_BASE_URL}/api/getPlayers.php`, {
                params: { teamName: team }
            });
            setPlayers(response.data.players ?? []);
        } catch (error) {
            console.error('Error fetching players:', error);
            setError('Error fetching players');
        }
    };

    const handleCloseModal = () => {
        setSelectedTeam(null);
        setOpenModal(false);
    };

    const handleDeletePlayer = async (index) => {
        try {
            const response = await axios.post(`${config.API_BASE_URL}/api/deletePlayer.php`, { teamName: selectedTeam, playerName: players[index] });
            if (response.data.success) {
                setPlayers(players.filter((_, i) => i !== index));
                console.log(response.data);
            } else {
                setError('Error deleting player');
            }
        } catch (error) {
            console.error('Error deleting player:', error);
            setError('Error deleting player');
        }
    };

    const handleEditPlayer = (index, updatedPlayerName) => {
        const updatedPlayers = [...players];
        updatedPlayers[index] = updatedPlayerName;
        setPlayers(updatedPlayers);
    };

    const handleSavePlayer = async () => {
        try {
            const response = await axios.post(`${config.API_BASE_URL}/api/updatePlayer.php`, { teamName: selectedTeam, players: players });
            if (response.data.success) {
                console.log(response.data);
            } else {
                setError('Error saving player');
            }
        } catch (error) {
            console.error('Error saving player:', error);
            setError('Error saving player');
        }
    };

    const handleAddPlayer = async () => {
        try {
            // Make API call to add player
            const response = await axios.post(`${config.API_BASE_URL}/api/addPlayer.php`, {
                teamName: selectedTeam,
                playerName: newPlayerName
            });
    
            if (response.data.success) {
                // Update local player list
                const updatedPlayers = [...players, newPlayerName];
                setPlayers(updatedPlayers);
                setNewPlayerName(''); // Reset input field
                console.log(response.data);
            } else {
                setError('Error adding player');
            }
        } catch (error) {
            console.error('Error adding player:', error);
            setError('Error adding player');
        }
    };    

    return (
        <Container maxWidth="md">

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Tabs value={activeTab} onChange={handleChangeTab}>
                    <Tab label="Matches" component={Link} to="/matches" />
                    <Tab label="Teams" component={Link} to="/teams" />
                    <Tab label="Awards" component={Link} to="/awards"/>
                </Tabs>
            </div>

            <h2>Teams</h2>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Team Name</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teams.map((team, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <TextField
                                        value={team}
                                        onChange={(e) => handleEditTeam(index, e.target.value)}
                                        onBlur={() => handleSaveTeam(index, teams[index])}
                                        fullWidth
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton color="secondary" onClick={() => handleDeleteTeam(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton color="primary" onClick={() => handleOpenModal(team)}>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell>
                                <TextField
                                    value={newTeamName}
                                    onChange={(e) => setNewTeamName(e.target.value)}
                                    placeholder="Enter new team name"
                                    fullWidth
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton color="primary" onClick={handleAddTeam}>
                                    <AddIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {error && <p style={{ color: 'red', marginTop: '20px' }}>{error}</p>}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box sx={radioGroupBoxstyle}>
                    <div>
                        <h2 id="team-details-modal">Team Details</h2>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Player Name</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {players.map((player, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <TextField
                                                    value={player}
                                                    onChange={(e) => handleEditPlayer(index, e.target.value)}
                                                    onBlur={() => handleSavePlayer()}
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton color="secondary" onClick={() => handleDeletePlayer(index)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell>
                                            <TextField
                                                value={newPlayerName}
                                                onChange={(e) => setNewPlayerName(e.target.value)}
                                                placeholder="Enter new player name"
                                                fullWidth
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <IconButton color="primary" onClick={handleAddPlayer}>
                                                <AddIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Box>
            </Modal>
        </Container>
    );
};

export default Teams;
