import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import axios from 'axios'
import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import config from '../config'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: 'auto',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  formContainer: {
    margin: '2rem 0 2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formGroup: {
    marginBottom: '2rem',
  },
  resetContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textField: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  textfieldWidth: {
    width: 200,
  },
  teamNameHeading: {
    fontWeight: 'bold',
    // padding: '16px',
    // paddingBottom: '0px',
  },
  center: {
    textAlign: 'center',
  },
}))
const HorizontalStepper = () => {
  const history = useHistory()
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [isSubmitting, setSubmitting] = React.useState(false)

  const steps = ['Team', 'Overs', 'Batting']
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const fetchPlayers = async (initialValues) => {
    try {
      const team1 = await axios.get(`${config.API_BASE_URL}/api/getPlayers.php?teamName=${initialValues.team1}`);
      const team2 = await axios.get(`${config.API_BASE_URL}/api/getPlayers.php?teamName=${initialValues.team2}`);
      localStorage.setItem('team1', JSON.stringify(team1.data.players))
      localStorage.setItem('team2', JSON.stringify(team2.data.players))
      // setTeams(response.data.teams ?? []);
    } catch (error) {
      console.error('Error fetching teams:', error);
      // setError('Error fetching teams');
    }
  };
  const matchData = JSON.parse(localStorage.getItem('matchdata'));
  const initialValues = {
    team1: matchData.runners[0],
    team2: matchData.runners[1],
    maxOver: '',
    maxWicket: '',
    maxBalls: 6,
    batting: '',
  }
  useEffect(() => {
    fetchPlayers(initialValues);
  }, []);
  const validationSchema = [
    Yup.object().shape({
      team1: Yup.string().required('Team Name is required'),
      team2: Yup.string().required('Team Name is required'),
    }),
    Yup.object().shape({
      maxOver: Yup.string().required('Over is required'),
    }),
    Yup.object().shape({
      maxWicket: Yup.string().required('Wicket is required').nullable(false),
    }),
    Yup.object().shape({
      maxBalls: Yup.string().required('Maximum Balls is required').nullable(false),
    }),
    Yup.object().shape({
      batting: Yup.string().required('Please choose who is Batting'),
    }),
  ]
  const updateMatchToss = async (val) => {

    const response = await axios.post(`${config.API_BASE_URL}/api/updateMatchToss.php`, {
      matchKey: matchData.matchKey,
      groupKey: matchData.groupKey,
      matchData: val
    });
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  }
  const currentValidationSchema = validationSchema[activeStep]
  function isLastStep() {
    return activeStep === steps.length - 1
  }
  const isTeamField = (values) => {
    let team1 = JSON.parse(localStorage.getItem('team1'))
    let team2 = JSON.parse(localStorage.getItem('team2'))
    if (team1.length == team2.length){
      if ((parseInt(values.maxWicket) + 1) == team1.length || parseInt(values.maxWicket) == team1.length){
        return true;
      }else{
        alert('Please fill the players or maintain the wickets1');
        return false;
      }
    }else{
      alert('Please fill the players or maintain the wickets2');
        return false;
    }
  }
  return (
    <div>
      <Stepper activeStep={activeStep} orientation='horizontal'>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className={classes.mainContainer}>
        <Formik
          enableReinitialize
          validationSchema={currentValidationSchema}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            handleNext()
            actions.setTouched({})
            actions.setSubmitting(false)
            if (isLastStep()) {
              if(isTeamField(values)){
                setSubmitting(true)
                updateMatchToss(values);
                const data = JSON.stringify(values)
                localStorage.setItem('data', data)
                history.push('/score')
                setSubmitting(false)
              }else{
                handleBack()
              }
            }
          }}
        >
          {(prp) => {
            const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = prp
            return (
              <form onSubmit={handleSubmit}>
                <div className={classes.formContainer}>
                  {activeStep === 0 && (
                    <div>
                      <div className={classes.formGroup}>
                        <TextField
                          id='team1'
                          name='team1'
                          label='Team1 Name*'
                          value={values.team1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.team1 && touched.team1 && errors.team1}
                          error={errors.team1 && touched.team1}
                          className={classes.textfieldWidth}
                        />
                      </div>
                      <div>
                        <Typography className={classes.center}>VS</Typography>
                      </div>
                      <div className={classes.formGroup}>
                        <TextField
                          id='team2'
                          name='team2'
                          label='Team2 Name*'
                          value={values.team2}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={errors.team2 && touched.team2 && errors.team2}
                          error={errors.team2 && touched.team2}
                          className={classes.textfieldWidth}
                        />
                      </div>
                    </div>
                  )}
                  {activeStep === 1 && (
                    <>
                      <div>
                        <div className={classes.formGroup}>
                          <Typography className={classes.teamNameHeading}>How many overs?</Typography>
                          <div className={classes.formGroup}>
                            <TextField
                              id='maxOver'
                              name='maxOver'
                              label='Overs*'
                              value={values.maxOver}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={errors.maxOver && touched.maxOver && errors.maxOver}
                              error={errors.maxOver && touched.maxOver}
                              className={classes.textfieldWidth}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className={classes.formGroup}>
                          <Typography className={classes.teamNameHeading}>How many balls per over?</Typography>
                          <div className={classes.formGroup}>
                            <TextField
                              id='maxBalls'
                              name='maxBalls'
                              label='Max Balls*'
                              value={values.maxBalls ?? 6}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={errors.maxBalls && touched.maxBalls && errors.maxBalls}
                              error={errors.maxBalls && touched.maxBalls}
                              className={classes.textfieldWidth}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className={classes.formGroup}>
                          <Typography className={classes.teamNameHeading}>How many wickets?</Typography>
                          <div className={classes.formGroup}>
                            <TextField
                              id='maxWicket'
                              name='maxWicket'
                              label='Wickets*'
                              value={values.maxWicket}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              helperText={errors.maxWicket && touched.maxWicket && errors.maxWicket}
                              error={errors.maxWicket && touched.maxWicket}
                              className={classes.textfieldWidth}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {activeStep === 2 && (
                    <div>
                      <div className={classes.formGroup}>
                        <FormControl component='fieldset'>
                          <FormLabel component='legend'>Who is Batting?</FormLabel>
                          <RadioGroup
                            name='batting'
                            value={values.batting.toString()}
                            onChange={(event) => {
                              setFieldValue('batting', event.currentTarget.value)
                            }}
                          >
                            <FormControlLabel value={values.team1} control={<Radio />} label={values.team1} />
                            <FormControlLabel value={values.team2} control={<Radio />} label={values.team2} />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  )}
                  <div>
                    <Button variant='contained' disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
                      Back
                    </Button>
                    <Button id='submit' disabled={isSubmitting} variant='contained' color='primary' type='submit'>
                      {isLastStep() ? 'Start Game' : 'Next'}
                    </Button>
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

export default HorizontalStepper
