import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Typography, Grid, Card, CardContent, CardHeader, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Button, Tabs, Tab } from '@material-ui/core';
import config from '../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Matches = () => {
    const [matchesData, setMatchesData] = useState(null);
    const history = useHistory()
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        const fetchMatches = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/api/getMatches.php`);
                setMatchesData(response.data.matches ?? []);
            } catch (error) {
                console.error('Error fetching matches:', error);
            }
        };

        fetchMatches();
    }, []);

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    const setMatchData = (matchdata, matchKey, groupKey = null) => {
        const dataWithKeys = { ...matchdata, matchKey, groupKey };
        const data = JSON.stringify(dataWithKeys)
        localStorage.setItem('matchdata', data)
        localStorage.setItem('data', JSON.stringify(matchdata.matchData))
        history.push('/start-match')
    }

    const fetchPlayers = async (matchData) => {
        try {
            const team1 = await axios.get(`${config.API_BASE_URL}/api/getPlayers.php?teamName=${matchData.runners[0]}`);
            const team2 = await axios.get(`${config.API_BASE_URL}/api/getPlayers.php?teamName=${matchData.runners[1]}`);
            localStorage.setItem('team1', JSON.stringify(team1.data.players))
            localStorage.setItem('team2', JSON.stringify(team2.data.players))
            // setTeams(response.data.teams ?? []);
        } catch (error) {
            console.error('Error fetching teams:', error);
            // setError('Error fetching teams');
        }
    };

    const scoreMatchData = (matchdata, matchKey, groupKey = null) => {
        const dataWithKeys = { ...matchdata, matchKey, groupKey };
        const data = JSON.stringify(dataWithKeys)
        fetchPlayers(matchdata)
        console.log(matchdata.matchData)
        localStorage.setItem('matchdata', data)
        localStorage.setItem('data', JSON.stringify(matchdata.matchData))
        history.push('/score')
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    if (!matchesData) {
        return <Typography variant="h2">Loading...</Typography>;
    }

    return (
        <Container maxWidth="md">

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Tabs value={activeTab} onChange={handleChangeTab}>
                    <Tab label="Matches" component={Link} to="/matches" />
                    <Tab label="Teams" component={Link} to="/teams" />
                    <Tab label="Awards" component={Link} to="/awards" />
                </Tabs>
            </div>

            <h2>Matches</h2>
            <Grid container spacing={3} justifyContent="center">
                {matchesData && Object.keys(matchesData.groups).map((groupKey) => (
                    <Grid item xs={12} key={groupKey}>
                        <Card variant="outlined">
                            <CardHeader title={capitalizeFirstLetter(groupKey)} />
                            <CardContent>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Title</TableCell>
                                                <TableCell>Runners</TableCell>
                                                <TableCell>Winner</TableCell>
                                                <TableCell>MOM</TableCell>
                                            <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(matchesData.groups[groupKey].matches).map(([matchKey, match]) => (
                                                <TableRow key={matchKey}>
                                                    <TableCell>{match.title}</TableCell>
                                                    <TableCell>{match.runners.join(' vs ')}</TableCell>
                                                    <TableCell>{match.winner}</TableCell>
                                                    <TableCell>{match.mom}</TableCell>
                                                    <TableCell>
                                                        {match.matchData.length === 0 ? (
                                                            <Button variant="contained" onClick={() => setMatchData(match, matchKey, groupKey)}>Start Match</Button>
                                                        ) : (
                                                            <Button variant="contained" onClick={() => scoreMatchData(match, matchKey, groupKey)}>Score</Button>
                                                        )}

                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardHeader title="Qualifiers" />
                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Title</TableCell>
                                            <TableCell>Runners</TableCell>
                                            <TableCell>Winner</TableCell>
                                            <TableCell>MOM</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={matchesData.qualifiers.title}>
                                            <TableCell>{matchesData.qualifiers.title}</TableCell>
                                            <TableCell>{matchesData.qualifiers.runners.join(' vs ')}</TableCell>
                                            <TableCell>{matchesData.qualifiers.winner}</TableCell>
                                            <TableCell>{matchesData.qualifiers.mom}</TableCell>
                                            <TableCell>
                                                {matchesData.qualifiers.matchData.length === 0 && (
                                                    <Button variant="contained" onClick={() => setMatchData(matchesData.qualifiers, 'qualifiers')}>Start Match</Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardHeader title="Final" />
                        <CardContent>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Title</TableCell>
                                            <TableCell>Runners</TableCell>
                                            <TableCell>Winner</TableCell>
                                            <TableCell>MOM</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={matchesData.final.title}>
                                            <TableCell>{matchesData.final.title}</TableCell>
                                            <TableCell>{matchesData.final.runners.join(' vs ')}</TableCell>
                                            <TableCell>{matchesData.final.winner}</TableCell>
                                            <TableCell>{matchesData.final.mom}</TableCell>
                                            <TableCell>
                                                {matchesData.final.matchData.length === 0 && (
                                                    <Button variant="contained" onClick={() => setMatchData(matchesData.final, 'final')}>Start Match</Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Matches;
