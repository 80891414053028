import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Typography, Grid, Card, CardContent, CardHeader, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Tabs, Tab } from '@material-ui/core';
import config from '../config';

const Awards = () => {
    const [awards, setAwards] = useState(null);
    const [activeTab, setActiveTab] = useState(2);

    useEffect(() => {
        const fetchAwards = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/api/getAwards.php`);
                setAwards(response.data.awards ?? []);
            } catch (error) {
                console.error('Error fetching awards:', error);
            }
        };

        fetchAwards();
    }, []);

    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue);
    };

    if (!awards) {
        return <Typography variant="h2">Loading...</Typography>;
    }

    return (
        <Container maxWidth="md">

            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <Tabs value={activeTab} onChange={handleChangeTab}>
                    <Tab label="Matches" component={Link} to="/matches" />
                    <Tab label="Teams" component={Link} to="/teams" />
                    <Tab label="Awards" component={Link} to="/awards"/>
                </Tabs>
            </div>

            <h2>Awards</h2>
            <Grid item xs={12}>
                <Card variant="outlined">
                    <CardContent>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Title</TableCell>
                                        <TableCell>Award</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Winner</TableCell>
                                        <TableCell>{awards?.winner ?? ''}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Runner Up 1</TableCell>
                                        <TableCell>{awards?.runner1 ?? ''}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Runner Up 2</TableCell>
                                        <TableCell>{awards?.runner2 ?? ''}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Players of the Tournament</TableCell>
                                        <TableCell>{awards?.pot ?? ''}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Best Batsmen</TableCell>
                                        <TableCell>{awards?.batsmen ?? ''}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Best Bowler</TableCell>
                                        <TableCell>{awards?.ball ?? ''}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Fair Play Award</TableCell>
                                        <TableCell>{awards?.fairplay ?? ''}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Container>
    );
};

export default Awards;
